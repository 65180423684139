import { useEffect, useState } from 'react';
import Image from 'next/image';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import styled from '@emotion/styled';
import { Box, Typography, Modal, Button } from '@mui/material';
import { Color } from '../../constants';
import { maintenanceSchedule } from '../../lib/maintenanceSchedule';
import SpeakerIcon from '../../public/twemoji_loudspeaker.svg';

// Timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export const MaintenanceModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const currentTime = dayjs().tz('Asia/Seoul');
  const { maintenanceStart, maintenanceEnd } = maintenanceSchedule;

  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const formatCheck = !!maintenanceStart && !!maintenanceEnd;
    const expireCheck =
      !!maintenanceEnd && currentTime.isBefore(dayjs(maintenanceEnd, 'YYYY-MM-DD HH:mm').tz('Asia/Seoul'));

    if (formatCheck && expireCheck) {
      setIsModalOpen(true);
    }
  }, [maintenanceStart, maintenanceEnd]);

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      disableScrollLock={true}
      aria-labelledby="maintenance-modal-title"
      aria-describedby="maintenance-modal-description"
    >
      <ModalBox>
        <HeadArea>
          <Image src={SpeakerIcon} width={52} height={52} alt="speaker_icon" />
          <Typography variant="subtitle2" ml={2} color={Color.textSecondary1} fontWeight={700}>
            알려드립니다!
          </Typography>
        </HeadArea>
        <Typography id="maintenance-modal-title" variant="h4" mt={5} fontWeight={700}>
          서비스 점검 안내
        </Typography>
        <Typography id="maintenance-modal-description" mt={3} variant="subtitle2" color={Color.textSecondary1}>
          잠시 후{' '}
          <span style={{ color: Color.textBlue, fontWeight: 800 }}>
            {dayjs(maintenanceStart, 'YYYY-MM-DD HH:mm').tz('Asia/Seoul').format('M월 D일 HH:mm')} 부터{' '}
            {dayjs(maintenanceEnd, 'YYYY-MM-DD HH:mm').tz('Asia/Seoul').format('M월 D일 HH:mm')} 까지
          </span>{' '}
          서비스 점검이 예정되어 있습니다. 해당 시간 동안 스포츠몬스터 앱 및 웹사이트 접속이 불가능하오니 이용에 참고
          부탁드립니다.
          <br />
          <br />
          감사합니다.
        </Typography>
        <ButtonArea>
          <Button variant={'contained'} fullWidth disableElevation={true} onClick={handleClose} size={'large'}>
            확인
          </Button>
        </ButtonArea>
      </ModalBox>
    </Modal>
  );
};

const ModalBox = styled(Box)`
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  border-radius: 2rem 2rem 0 0;
  padding: 32px;
  max-width: 640px;
  @media only screen and (max-width: 640px) {
    left: 0;
    right: 0;
  }
  @media only screen and (min-width: 641px) and (max-width: 1399px) {
    width: 100%;
    left: calc(50% - 320px);
  }
  @media only screen and (min-width: 1400px) {
    width: 100%;
    left: calc(50% - 45px);
  }
`;

const HeadArea = styled(Box)`
  display: flex;
  align-items: center;
`;

const ButtonArea = styled(Box)`
  margin-top: 7rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;
