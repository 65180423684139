import React, { useEffect, useState } from 'react';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { sampleSize } from 'lodash';
import { Container, Box, Typography, IconButton } from '@mui/material';
import { Color } from '../constants';
import BottomNav from '../components/GNB/BottomNav';
import { GET_ATTRACTIONS, QUERY_EVENTLIST, QUERY_USERCOUPON } from '../apollo/queries';
import { IPaginate, IResult, DocumentType } from '../apollo/types';
import useTempInfo from '../hooks/useTempInfo';
import { Attraction, Event, UserCoupon } from 'swingby-shared';
import { isLoggedInVar, userVar } from '../apollo/cache';
import { client, useApolloNetworkStatus } from '../apollo';
import { MaintenanceModal } from '../components/Modal/MaintenanceModal';
import { useOrderList } from '../operations/queries/getOrderList';
import Logo from '../public/smob_logo.svg';
import LogoBy from '../public/smob_logo_by_smon.png';
import QuickAbout from '../public/home_quick_about.png';
import QuickTip from '../public/home_quick_tip.png';
import QuickBand from '../public/home_quick_band.png';

const MainBanner = dynamic(() => import('../components/CarouselBanner/MainBanner'), { ssr: false });
const MyTicketContainer = dynamic(() => import('../components/Container/MyTicketContainer'), { ssr: false });
const AttractionContainer = dynamic(() => import('../components/Container/AttractionContainer'), { ssr: false });
const MainSkeleton = dynamic(() => import('../components/Skeleton/Main'), { ssr: false });
const NoticeWelcomeCouponSnackBar = dynamic(() => import('../components/SnackBar/NoticeWelcomeCouponSnackBar'), {
  ssr: false,
});
const CheckWelcomeCouponModal = dynamic(() => import('../components/Modal/CheckWelcomeCouponModal'), { ssr: false });

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Home({ attractions }: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter();
  const { removeTempInfo } = useTempInfo();
  const { numPendingQueries: loading } = useApolloNetworkStatus();
  const isLoggedIn = isLoggedInVar();
  const user = useReactiveVar(userVar);

  const [showWelcomeCouponModel, setShowWelcomeCouponModal] = useState<boolean>(false);
  const [isSnackBarSessionOn, setSnackBarSession] = useState<boolean>(true);
  const [showNoticeWelcomeCouponSnackBar, setShowNoticeWelcomeCouponSnackBar] = useState<boolean>(false);

  const checkDate = dayjs.tz(new Date(), 'Asia/Seoul').diff(dayjs.tz('2022-03-01', 'Asia/Seoul'));
  const couponName = checkDate < 0 ? '입장권 10,000원 할인 쿠폰' : '첫 구매 할인쿠폰';

  const { usableOrders } = useOrderList();

  const { data: events } = useQuery<IResult<IPaginate<DocumentType<Event>>>>(QUERY_EVENTLIST, {
    fetchPolicy: 'cache-and-network',
    variables: { filter: { exposure: 'enabled' } },
  });

  const validEvents = events?.result.items.filter(
    ({ exposureEnd }) => !exposureEnd || dayjs(exposureEnd).isAfter(dayjs()),
  );

  useEffect(() => {
    removeTempInfo();
    if (!isLoggedIn && isSnackBarSessionOn) {
      setShowNoticeWelcomeCouponSnackBar(true);
    }
  }, []);

  const [checkForNewUserGift, { data: userCoupon }] = useLazyQuery<IResult<UserCoupon>>(QUERY_USERCOUPON, {
    onCompleted() {
      setShowWelcomeCouponModal(true);
    },
  });

  const prevPath = globalThis?.sessionStorage?.getItem('prevPath');
  useEffect(() => {
    if (user && prevPath?.startsWith('/signup')) {
      checkForNewUserGift({ variables: { filter: { owner: user.id, metaData: { phoneNumber: user.cellPhone } } } });
    }
  }, [prevPath]);

  if (loading) {
    return <MainSkeleton />;
  }

  return (
    <>
      <Box
        sx={{
          width: 1,
          maxWidth: 640,
          height: 64,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          background: Color.backgroundSurface,
          zIndex: 10,
        }}
      >
        <Box>
          <Image unoptimized src={Logo} width={84} height={24} alt="logo" />
          &nbsp;
          <Image unoptimized src={LogoBy} width={100} height={7} alt="logo_by" />
        </Box>
      </Box>
      <Container disableGutters sx={{ pt: 8, pb: 7, flex: '1 1 0' }}>
        <Box>
          <MainBanner events={validEvents || []} />
        </Box>
        <Box p={3}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
            <Box sx={{ textAlign: 'center' }}>
              <IconButton size="small" onClick={() => router.push('/brand')}>
                <Image unoptimized src={QuickAbout} width={96} height={96} alt="quick_about" />
              </IconButton>
              <Typography variant="caption">스몹소개</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <IconButton size="small" onClick={() => router.push('/brand/tip')}>
                <Image unoptimized src={QuickTip} width={96} height={96} alt="quick_tip" />
              </IconButton>
              <Typography variant="caption">이용안내</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <IconButton size="small" onClick={() => router.push(isLoggedIn ? '/play' : '/login')}>
                <Image unoptimized src={QuickBand} width={96} height={96} alt="quick_band" />
              </IconButton>
              <Typography variant="caption">밴드연결</Typography>
            </Box>
            <MyTicketContainer noTicket={!usableOrders?.length} order={usableOrders?.[0]} />
          </Box>
        </Box>

        <AttractionContainer attractions={attractions || []} />
      </Container>
      <BottomNav />

      <MaintenanceModal />
      <CheckWelcomeCouponModal
        isModalOpen={showWelcomeCouponModel}
        setModalOpen={setShowWelcomeCouponModal}
        userCoupon={userCoupon?.result}
        couponName={couponName}
      />
      {checkDate > 0 && (
        <NoticeWelcomeCouponSnackBar
          isModalOpen={showNoticeWelcomeCouponSnackBar}
          setModalOpen={setShowNoticeWelcomeCouponSnackBar}
          setModalSession={setSnackBarSession}
        />
      )}
    </>
  );
}

export const getStaticProps: GetStaticProps<{ attractions: Attraction[] }> = async () => {
  const { data } = await client.query<IResult<Attraction[]>>({
    query: GET_ATTRACTIONS,
    variables: { filter: { isOperating: true } },
  });

  const attractions = sampleSize(data.result, 6);

  return {
    props: {
      attractions,
    },
  };
};
