import { useSetRecoilState, useRecoilState } from 'recoil';
import { selectedTimeState, ticketDateState, onlineItemsState } from '../atoms';
import { produce } from 'immer';

const useTempInfo = () => {
  const setTimeState = useSetRecoilState(selectedTimeState);
  const setDateState = useSetRecoilState(ticketDateState);
  const [onlineItems, setOnlineItems] = useRecoilState(onlineItemsState);

  const removeTempInfo = () => {
    setTimeState(null);
    setDateState(null);
    setOnlineItems(
      produce(onlineItems, (draft) => {
        draft.length = 0;
      }),
    );
    localStorage.removeItem('recoil-persist');
  };

  return {
    removeTempInfo,
  };
};

export default useTempInfo;
