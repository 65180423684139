import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { chain } from 'lodash';
import { isLoggedInVar } from '../../apollo/cache';
import { QUERY_ORDERLIST } from '../../apollo/queries';
import { Order } from 'swingby-shared/dist/interfaces';
import { OrderStatus, UserTicketStatus } from 'swingby-shared/dist/enums';
import type { IResult } from '../../apollo/types';

export const useOrderList = () => {
  const isLoggedIn = isLoggedInVar();

  const { data: orders, loading } = useQuery<IResult<Order[]>>(QUERY_ORDERLIST, {
    skip: !isLoggedIn,
    fetchPolicy: 'cache-and-network',
    variables: { filter: { _operators: { status: { in: [OrderStatus.Paid, OrderStatus.Cancelled] } } } },
  });

  // 사용완료/취소
  const useOrCancelled = chain(orders?.result)
    .filter(({ userTickets }) => {
      return userTickets.every(({ status }) => [UserTicketStatus.Cancelled, UserTicketStatus.Used].includes(status));
    })
    .sortBy(({ userTickets, payments }) => userTickets[0]?.enterAt || payments[0]?.refundedAt)
    .reverse()
    .value();

  // 사용가능
  const usableOrders = chain(orders?.result)
    .filter(({ userTickets }) => {
      return (
        userTickets.some(({ status }) => status === UserTicketStatus.Paid) &&
        dayjs(userTickets[0].reservedAt).diff(dayjs(), 'day') >= 0
      );
    })
    .sortBy(({ userTickets }) => userTickets[0].reservedAt)
    .value();

  return { loadingOrders: loading, useOrCancelled, usableOrders };
};
